import { useQuery } from "@tanstack/react-query";

import OrganizationsApi from "@user/api/OrganizationsApi";
import { OrganizationRugbyGame } from "@/domains/user/types/OrganizationRugbyGame";
import { cacheKeyBuilder } from "@/domains/user/api/apiCache";

function useOrganizationRugbyGames(organizationId?: string): {
  organizationRugbyGames: OrganizationRugbyGame[];
  isLoading: boolean;
} {
  // Get the paginated organization games
  const { data: organizationRugbyGames = [], isLoading } = useQuery({
    queryKey: cacheKeyBuilder.organizationRugbyGames(organizationId),
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationGamesByOrganizationId(organizationId);
    },
    enabled: !!organizationId,
  });

  return {
    organizationRugbyGames,
    isLoading,
  };
}

export default useOrganizationRugbyGames;
