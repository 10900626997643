import React, { useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";

import userContext, { UserContext } from "@/contexts/userContext";
import { Sport } from "@user/types/Sport";
import useUserMe from "@user/hooks/useUserMe";
import { Role } from "@user/types/Role";
import { Permission } from "@user/types/Permission";
import smartlookManager from "@/utils/smartlook";
import { apisScopes } from "@/auth/msalManager";

function UserProvider({ children }: { children: React.ReactNode }) {
  const { instance, accounts, inProgress } = useMsal();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectionLoading, setIsConnectionLoading] = useState<boolean>(true);

  const [currentSport, setCurrentSport] = useState<Sport | undefined>(undefined);

  useEffect(
    function initIsConnected() {
      (async () => {
        if (inProgress == "login" || inProgress == "handleRedirect") {
          // Login in progress
          setIsConnected(false);
          setIsConnectionLoading(true);
        } else if (inProgress == "none" && !accounts.length) {
          // Logout completed
          setIsConnected(false);
          setIsConnectionLoading(false);
        } else if (inProgress == "none" && accounts.length) {
          // Login completed
          await instance.initialize();
          setIsConnected(true);
          setIsConnectionLoading(false);
        }
      })(); // Init + trigger function
    },
    [inProgress, accounts],
  );

  // Fetch user on connection
  const {
    user,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useUserMe({ skip: !isConnected });
  const organization = user?.organization ?? undefined;
  const role: Role | undefined = user?.role ?? undefined;
  const permissions: Permission[] = user?.permissions ?? [];
  const sports: Sport[] = user?.sports ?? [];

  // add user info to smartlook
  useEffect(
    function addUserInfoToSmartlook() {
      if (isConnected && user) {
        smartlookManager.addIdentity(user.email, user.firstName, user.lastName);
      }
    },
    [isConnected, user],
  );

  // Initialize current sport with the first sport
  useEffect(
    function initCurrentSport() {
      if (sports.length > 0 && currentSport === undefined) {
        setCurrentSport(sports[0]);
      }
    },
    [sports],
  );

  function changeSport(sportId: string) {
    const newSport = sports.find((sport) => sport.id === sportId);
    if (!newSport) {
      throw new Error(`Sport with id ${sportId} not found`);
    }
    setCurrentSport(newSport);
  }

  /**
   * redirect the user to the azure B2C login page to start the authentication process
   * @param callbackUrl The URL to redirect to after login
   * @returns void
   */
  function login(callbackUrl: string) {
    instance.loginRedirect({
      scopes: apisScopes,
      state: JSON.stringify({ callbackUrl }), // Save the redirect URL in the state
    });
  }

  /**
   * cleanup the user session and redirect the user to the azure B2C logout page
   * @returns void
   */
  function logout() {
    instance.logoutRedirect();
  }

  const isLoading = isUserLoading;

  const userCtx: UserContext = useMemo(
    () => ({
      user: user,
      organization: organization,
      role: role,
      permissions: permissions,
      sports: sports,
      currentSport: currentSport,
      isConnected,
      isLoading,
      isConnectionLoading,
      isUserLoading,
      isUserFetching,
      changeSport,
      login,
      logout,
    }),
    [
      user,
      organization,
      role,
      permissions,
      sports,
      currentSport,
      isConnected,
      isLoading,
      isConnectionLoading,
      isUserLoading,
      isUserFetching,
      changeSport,
      login,
      logout,
    ],
  );

  return <userContext.Provider value={userCtx}>{children}</userContext.Provider>;
}

export default UserProvider;
