import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { UpdateUserInputs } from "@user/api/UsersApi";
import Loader from "@pelote/components/loader/Loader";
import usePageAdminUserEdit from "./usePageAdminUserEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import FormCheckbox from "@/ui-lib/form-checkbox/FormCheckbox";
import Button from "@/ui-lib/button/Button";
import { convertISODateToDisplayFormat } from "@/utils/convertISODateToDisplayFormat";

import "./pageAdminUserEdit.scss";

const PageAdminUserEdit = () => {
  const { t } = useTranslation();
  const { navigateBack } = useNavigateInApp();

  const {
    user,
    organizationsOptions,
    changeOrganization,
    changeRole,
    rolesOptions,
    selectedRole,
    updateUser,
    sendWelcomeEmail,
    isLoading,
  } = usePageAdminUserEdit();

  // Set the form values when the user is fetched
  useEffect(() => {
    if (!isLoading && user) {
      setValue("firstName", user?.firstName);
      setValue("lastName", user?.lastName);
      setValue("email", user?.email);
      setValue("providerId", user?.providerId);
      setValue("isActive", user?.isActive);
      setValue("organizationId", user?.organization.id);
      setValue("roleId", user?.role.id);
    }
  }, [user, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateUserInputs>({});

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-user-edit">
      <form className="form" onSubmit={handleSubmit(updateUser)}>
        <div className="form-fields">
          <FormInput
            id="firstName"
            className="form-input"
            labelName={t("admin.user.first-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.first-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.firstName?.message}
            register={register}
          />
          <FormInput
            id="lastName"
            className="form-input"
            labelName={t("admin.user.last-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.last-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.lastName?.message}
            register={register}
          />
          <FormInput
            id="email"
            className="form-input"
            labelName={t("admin.user.email")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.email")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.email?.message}
            register={register}
          />
          <FormInput
            id="providerId"
            className="form-input"
            labelName={t("admin.user.provider-id")}
            type={InputType.text}
            disabled={true}
            register={register}
          />
          <FormSelect
            id="organizationId"
            className="form-select"
            labelName={t("admin.organization.organization")}
            options={organizationsOptions}
            textToDisplayIfError={`${t("admin.organization.organization")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.organizationId?.message}
            register={register}
            onChange={changeOrganization}
          />
          <FormSelect
            id="roleId"
            labelName={t("admin.role.role")}
            options={rolesOptions}
            selectedValue={selectedRole?.value.toString()}
            textToDisplayIfError={`${t("admin.role.role")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.roleId?.message}
            register={register}
            onChange={changeRole}
          />
          <FormCheckbox
            id="isActive"
            className="form-input"
            labelName={t("admin.user.enabled")}
            register={register}
          />
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigateBack({ fallbackRoute: "/admin/user/users" })}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
      <div className="email-section">
        <div className="welcome">
          <div className="label">{t("admin.user.date-of-last-welcome-email-sending")}</div>
          <div className="value">
            {user?.welcomeEmailSentAt
              ? convertISODateToDisplayFormat(user?.welcomeEmailSentAt)
              : "---"}
          </div>
        </div>
        <Button
          text={t("admin.user.send-welcome-email")}
          onClick={() => {
            if (user) sendWelcomeEmail(user.id);
          }}
        />
      </div>
    </div>
  );
};

export default PageAdminUserEdit;
