import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import useOrganization from "@user/hooks/useOrganization";
import { APIError } from "@user/types/ApiError";
import { Organization } from "@user/types/Organization";
import OrganizationsApi from "@user/api/OrganizationsApi";
import usePaginatedTokenOperations from "@user/hooks/usePaginatedTokenOperations";
import { TokenOperation } from "@user/types/TokenOperation";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { cacheKeyInvalidator } from "@/domains/user/api/apiCache";

function usePageAdminOrganizationTokens(): {
  organization: Organization | undefined;
  refillTokens: (numberOfTokens: number) => void;
  isLoading: boolean;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  paginatedTokenOperations: PaginatedModel<TokenOperation> | undefined;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setCurrentPageSize: (pageSize: number) => void;
  pageSize: number;
} {
  const { id: organizationId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsEditModalOpen] = useState(false);

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setCurrentPageSize] = useState(10);

  // Fetch the organization
  const { organization, isLoading: isOrganizationLoading } = useOrganization({
    organizationId,
    fetchDetails: !organizationId,
  });

  // Get the paginated token operations
  const { paginatedTokenOperations, isLoading: isTokenOperationsLoading } =
    usePaginatedTokenOperations({
      organizationId: organizationId,
      page: currentPage,
      pageSize,
    });

  // Update organization token operation
  const { mutate: refillTokensByOrganizationId } = useMutation({
    mutationFn: organizationId
      ? (numberOfTokens: number) =>
          OrganizationsApi.refillTokensByOrganizationId(organizationId, numberOfTokens)
      : undefined,
    onSuccess: (data: Organization) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organization(data.id),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizations(),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationTokenOperations(organizationId),
      });
      toast.success(t("admin.organization-tokens.organization-refill-tokens-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.organization-tokens.organization-refill-tokens-failed"), {
        autoClose: 3000,
      });
    },
  });
  function refillTokens(numberOfTokens: number) {
    refillTokensByOrganizationId(numberOfTokens);
  }

  // Open the edit modal
  function openModal() {
    setIsEditModalOpen(true);
  }

  function closeModal() {
    setIsEditModalOpen(false);
  }

  const isLoading = isTokenOperationsLoading || isOrganizationLoading;

  return {
    organization,
    refillTokens,
    isLoading,
    isModalOpen,
    openModal,
    closeModal,
    paginatedTokenOperations,
    setCurrentPage,
    currentPage,
    setCurrentPageSize,
    pageSize,
  };
}

export default usePageAdminOrganizationTokens;
