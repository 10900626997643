import { Sport } from "@user/types/Sport";
import useUser from "@user/hooks/useUser";
import useOrganization from "@/domains/user/hooks/useOrganization";

/**
 * This interface is used to extend the Sport with data from the userSport if any
 */
export interface ExtendedSports extends Sport {
  isAssigned: boolean;
}

function usePageAdminUserSports({ userId }: { userId?: string }): {
  extendedUserSports: ExtendedSports[];
  addSportToUser: (sportId: string) => void;
  removeSportFromUser: (sportId: string) => void;
  isLoading: boolean;
} {
  // Get the user sports and utils functions
  const {
    user,
    sports: userSports,
    isLoading: isUserSportsLoading,
    addSportToUser,
    removeSportFromUser,
  } = useUser({ userId, withSports: true });

  // Get the user organization sports
  const { sports: organizationSports, isLoading: isOrganizationSportsLoading } = useOrganization({
    organizationId: user?.organization.id,
    fetchSports: true,
  });

  // Build the extended user sports
  const extendedUserSports = organizationSports
    .map((organizationSport) => {
      const isAssigned = userSports.some((userSport) => userSport.id === organizationSport.id);
      return { ...organizationSport, isAssigned };
    })
    .sort((a, b) => (a.domain.name > b.domain.name || a.name > b.name ? 1 : -1));

  // Build the global isLoading flag
  const isLoading = isUserSportsLoading || isOrganizationSportsLoading;

  return {
    extendedUserSports,
    addSportToUser,
    removeSportFromUser,
    isLoading,
  };
}

export default usePageAdminUserSports;
