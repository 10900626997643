import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminOrganizationRoles from "./usePageAdminOrganizationRoles";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import Button from "@/ui-lib/button/Button";
import { Role } from "@user/types/Role";
import useNavigateInApp from "@/hooks/useNavigateInApp";

import "./pageAdminOrganizationRoles.scss";

const PageAdminOrganizationRoles = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigateInApp();

  const {
    organizationId,
    paginatedRoles,
    rolesPage,
    rolesPageSize,
    setRolesPage,
    deleteRole,
    isLoading,
  } = usePageAdminOrganizationRoles();

  // Organization roles table initialization
  const columlHelperOrganizationRoles = createColumnHelper<Role>();
  const columnsOrganizationRoles = [
    columlHelperOrganizationRoles.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.name"),
    }),
    columlHelperOrganizationRoles.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.description"),
    }),
    columlHelperOrganizationRoles.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => navigateTo(`/admin/user/roles/${info.row.original.id}`)}
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => deleteRole(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-roles">
      <div className="title-bar">
        <div className="title">{t("admin.organization.organization-roles")}</div>
        <Button
          text={t("admin.role.create-role")}
          onClick={() => navigateTo(`/admin/user/roles/create?organizationId=${organizationId}`)}
        />
      </div>
      <div className="table-organization-roles">
        <Table
          columns={columnsOrganizationRoles}
          data={paginatedRoles?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedRoles?.pagination.totalItems ?? 0}
            itemsPerPage={rolesPageSize}
            currentPage={rolesPage}
            onClick={setRolesPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminOrganizationRoles;
