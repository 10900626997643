import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { toast } from "react-toastify";

import useCompetitions from "@/domains/rugby/hooks/useCompetitions";
import useOrganization from "@user/hooks/useOrganization";
import { Competition } from "@/domains/rugby/types/Competitions";
import { APIError } from "@user/types/ApiError";
import OrganizationCompetitionsApi, {
  UpdateOrganizationCompetitions,
  CreateOrganizationCompetitions,
} from "@user/api/OrganizationCompetitionsApi";
import { OrganizationRugbyCompetition } from "@/domains/user/types/OrganizationRugbyCompetition";
import useOrganizationRugbyGames from "./useOrganizationRugbyGames";
import { OrganizationRugbyGame } from "@/domains/user/types/OrganizationRugbyGame";
import {
  OrganizationSetting,
  OrganizationSettingEntityType,
} from "@user/types/OrganizationSetting";
import { CreateOrganizationSettingInputs } from "@user/api/OrganizationsApi";
import { Sport, SportKey } from "@user/types/Sport";
import useOrganizationSetting from "@user/hooks/useOrganizationSetting";
import { cacheKeyInvalidator } from "@user/api/apiCache";
import useSports from "@user/hooks/useSports";

export interface ExtendedCompetition extends Competition {
  sportName: string;
  isFavorite: boolean;
  organizationSettingId?: string;
  organizationCompetitionId?: string;
  hasFullAccess: boolean;
  hasDynamicReportAccess: boolean;
  hasNoAccess: boolean;
  organizationGamesBought?: OrganizationRugbyGame[];
}

function usePageAdminOrganizationCompetitions(): {
  extendedCompetitions: ExtendedCompetition[];
  sportRugby?: Sport;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  createOrganizationCompetition: (inputs: CreateOrganizationCompetitions) => void;
  updateOrganizationCompetition: (inputs: UpdateOrganizationCompetitions) => void;
  createOrganizationSetting: (
    organizationId: string,
    settingInputs: CreateOrganizationSettingInputs,
  ) => void;
  deleteOrganizationSetting: (organizationId: string, organizationSettingId: string) => void;
} {
  const { id: organizationId } = useParams();
  const queryClient = useQueryClient();

  const {
    organizationCompetitions,
    organizationSettings,
    isLoading: isOrganizationLoading,
    isError: isOrganizationError,
  } = useOrganization({
    organizationId,
    fetchCompetitions: true,
    fetchSettings: true,
  });

  const {
    competitions,
    isLoading: isAllCompetitionsLoading,
    isError: isAllCompetitionsError,
  } = useCompetitions({
    skip: !organizationId,
  });

  const { sports, isLoading: isSportsLoading, isError: isSportsError } = useSports();

  const { createOrganizationSetting, deleteOrganizationSetting } = useOrganizationSetting({
    skip: true,
  });

  const { organizationRugbyGames, isLoading: isAllGamesBoughtLoading } =
    useOrganizationRugbyGames(organizationId);

  // Build sport Rugby XV
  const sportRugby = sports.find((sport) => sport.key === SportKey.RUGBY_XV);

  // Build the extended competitions which will be used in the page table
  const extendedCompetitions: ExtendedCompetition[] =
    competitions?.map((competition) => {
      const organizationCompetition = organizationCompetitions?.find((organizationCompetition) => {
        return organizationCompetition.competitionId === competition.id;
      });

      const organizationSettingWithFavoriteCompetition = organizationSettings.find(
        (setting: OrganizationSetting) =>
          setting.entityType === OrganizationSettingEntityType.FAVORITE_COMPETITION &&
          setting.entityId === competition.id.toString() &&
          setting.settingValue === "true",
      );

      const competitionGamesBought: OrganizationRugbyGame[] = organizationRugbyGames
        .filter((game) => game.competitionId === competition.id)
        .sort((a, b) => {
          return new Date(b.game.date).getTime() - new Date(a.game.date).getTime();
        });

      const extendedCompetition: ExtendedCompetition = {
        ...competition,
        isFavorite: !!organizationSettingWithFavoriteCompetition,
        organizationSettingId: organizationSettingWithFavoriteCompetition?.id,
        sportName: sportRugby?.name ?? "",
        organizationCompetitionId: organizationCompetition?.id,
        hasFullAccess: organizationCompetition?.hasFullAccess ?? false,
        hasDynamicReportAccess: organizationCompetition?.hasDynamicReportAccess ?? false,
        hasNoAccess: organizationCompetition?.hasNoAccess ?? false,
        organizationGamesBought: competitionGamesBought ?? [],
      };

      return extendedCompetition;
    }) ?? [];

  // Create a new organization competition
  const { mutate: createCompetitionsRugby, isPending: isCreationPending } = useMutation({
    mutationFn: (newOrganizationCompetition: CreateOrganizationCompetitions) => {
      return OrganizationCompetitionsApi.create(newOrganizationCompetition);
    },
    onSuccess: (data: OrganizationRugbyCompetition) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationRugbyCompetitions(data.organizationId),
      });
      toast.success(t("admin.organization-competitions.organization-competition-update-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.organization-competitions.organization-competition-update-failed"), {
        autoClose: 3000,
      });
    },
  });
  function createOrganizationCompetition(inputs: CreateOrganizationCompetitions) {
    createCompetitionsRugby(inputs);
  }

  // Update an organization competition
  const { mutate: updateCompetitionsRugby } = useMutation({
    mutationFn: (variables: { updatedOrganizationCompetition: UpdateOrganizationCompetitions }) =>
      OrganizationCompetitionsApi.update(
        variables.updatedOrganizationCompetition.id
          ? variables.updatedOrganizationCompetition.id
          : "",
        variables.updatedOrganizationCompetition,
      ),
    onSuccess: (data: OrganizationRugbyCompetition) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationRugbyCompetitions(data.organizationId),
      });
      toast.success(t("admin.organization-competitions.organization-competition-update-success"), {
        autoClose: 3000,
      });
    },

    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.organization-competitions.organization-competition-update-failed"), {
        autoClose: 3000,
      });
    },
  });
  function updateOrganizationCompetition(inputs: UpdateOrganizationCompetitions) {
    updateCompetitionsRugby({ updatedOrganizationCompetition: inputs });
  }

  const isLoading =
    isOrganizationLoading || isAllCompetitionsLoading || isSportsLoading || isAllGamesBoughtLoading;

  const isError = isOrganizationError || isAllCompetitionsError || isSportsError;

  const isFetching = isCreationPending;

  return {
    extendedCompetitions,
    sportRugby,
    isLoading,
    isError,
    isFetching,
    createOrganizationCompetition,
    updateOrganizationCompetition,
    createOrganizationSetting,
    deleteOrganizationSetting,
  };
}

export default usePageAdminOrganizationCompetitions;
