import axios from "axios";

import { apiUrl } from "@/config";
import {
  castApiOrganizationRugbyCompetitionToOrganizationRugbyCompetition,
  getDefaultHeaders,
} from "./utils";
import { OrganizationRugbyCompetition } from "@/domains/user/types/OrganizationRugbyCompetition";
import { APIOrganizationRugbyCompetition } from "./apiTypes";

export interface CreateOrganizationCompetitions {
  organizationId: string;
  competitionId: number;
  hasFullAccess?: boolean;
  hasDynamicReportAccess?: boolean;
  hasNoAccess?: boolean;
}
export interface UpdateOrganizationCompetitions extends CreateOrganizationCompetitions {
  id: string;
}

export default class OrganizationCompetitionsApi {
  static domain: string = "user";
  static resource: string = "organization-competitions-rugby";

  static async create(
    createOrganizationCompetition: Partial<CreateOrganizationCompetitions>,
  ): Promise<OrganizationRugbyCompetition> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}`;
    const data = {
      competition_id: createOrganizationCompetition.competitionId,
      organization_id: createOrganizationCompetition.organizationId,
      has_full_access: createOrganizationCompetition.hasFullAccess,
      has_dynamic_report_access: createOrganizationCompetition.hasDynamicReportAccess,
      has_no_access: createOrganizationCompetition.hasNoAccess,
    };
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: data,
    });

    const apiOrganizationCompetition: APIOrganizationRugbyCompetition = response.data;

    const organizationCompetition: OrganizationRugbyCompetition =
      castApiOrganizationRugbyCompetitionToOrganizationRugbyCompetition(apiOrganizationCompetition);

    return organizationCompetition;
  }

  static async update(
    id: string,
    updateOrganizationCompetition: Partial<UpdateOrganizationCompetitions>,
  ): Promise<OrganizationRugbyCompetition> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${apiUrl}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateOrganizationCompetition,
    });

    const apiOrganizationCompetition: APIOrganizationRugbyCompetition = response.data;

    const organizationCompetition: OrganizationRugbyCompetition =
      castApiOrganizationRugbyCompetitionToOrganizationRugbyCompetition(apiOrganizationCompetition);

    return organizationCompetition;
  }
}
