import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { PiEraserFill } from "react-icons/pi";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminUserList from "./usePageAdminUserList";
import { User } from "@user/types/User";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import StringInput from "@/ui-lib/input/StringInput";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import Checkbox from "@/ui-lib/checkbox/Checkbox";

import "./pageAdminUserList.scss";

const PageAdminUserList = () => {
  const { navigateTo } = useNavigateInApp();
  const { t } = useTranslation();

  const {
    paginatedUsers,
    filterByName,
    changeFilterByName,
    resetFilterByName,
    filterByOrganization,
    changeFilterByOrganization,
    resetFilterByOrganization,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
    deleteUser,
  } = usePageAdminUserList();

  const columlHelper = createColumnHelper<User>();
  const columns = [
    columlHelper.accessor("organization.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.organization.organization"),
    }),
    columlHelper.accessor("lastName", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.last-name"),
    }),
    columlHelper.accessor("firstName", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.first-name"),
    }),
    columlHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.email"),
    }),
    columlHelper.accessor("isActive", {
      cell: (info) => <Checkbox defaultChecked={info.getValue()} disabled />,
      header: () => t("admin.organization.enabled"),
    }),
    columlHelper.accessor("role.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.role"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => navigateTo(`/admin/user/users/${info.row.original.id}`)}
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => deleteUser(info.row.original.id, info.row.original.organization.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  return (
    <div className="page-admin-user-list">
      <AdminHeader text={t("admin.user.users")} />
      <FormButton
        labelName={t("admin.user.create-user")}
        onClick={() => navigateTo(`/admin/user/users/create`)}
      />
      <div className="search-bar">
        <div className="search-bar-input search-by-name">
          <StringInput
            placeholder={t("admin.user.search-by-name")}
            value={filterByName}
            onChange={changeFilterByName}
          />
          <button type="button" onClick={resetFilterByName}>
            <PiEraserFill size={25} />
          </button>
        </div>
        <div className="search-bar-input search-by-organization">
          <StringInput
            placeholder={t("admin.user.search-by-organization")}
            value={filterByOrganization}
            onChange={changeFilterByOrganization}
          />
          <button type="button" onClick={resetFilterByOrganization}>
            <PiEraserFill size={25} />
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="users-list-content">
          <Table columns={columns} data={paginatedUsers?.items ?? []} hasGlobalFilter={true} />
          <div className="pagination">
            <Pagination
              totalItems={paginatedUsers?.pagination.totalItems ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminUserList;
