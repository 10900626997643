import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import UsersApi, { CreateUserInputs, CreateUserSettingInputs } from "@user/api/UsersApi";
import { User } from "@user/types/User";
import { Sport } from "@user/types/Sport";
import { cacheKeyBuilder, cacheKeyInvalidator } from "../api/apiCache";
import { APIError, APIErrorType } from "../types/ApiError";
import useUserSetting from "./useUserSetting";

export interface CreateUserAsyncInputs extends CreateUserInputs {
  sportIds?: string[]; // Optional sport IDs to assign to the user
  settings?: CreateUserSettingInputs[]; // Optional settings to initialize the user
}

function useUser({
  userId,
  skip = false,
  withSports = false,
}: Partial<{
  userId?: string;
  skip?: boolean; // Deactivate the hook
  withSports?: boolean; // Enable fetching the sports
}> = {}): {
  user?: User;
  sports: Sport[];
  isLoading: boolean;
  isError: boolean;
  createUserAsync: (inputs: CreateUserAsyncInputs) => Promise<User>;
  addSportToUser: (sportId: string) => void;
  removeSportFromUser: (sportId: string) => void;
} {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // Fetch the user
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useQuery({
    queryKey: cacheKeyBuilder.user(userId),
    queryFn: () => {
      if (!userId) return;
      return UsersApi.show(userId);
    },
    enabled: !!userId && !skip,
  });

  // Fetch the sports if required
  const {
    data: sports = [],
    isLoading: isSportsLoading,
    isError: isSportsError,
  } = useQuery({
    queryKey: cacheKeyBuilder.userSports(userId),
    queryFn: () => {
      if (!userId) return;
      return UsersApi.getAllUserSports(userId);
    },
    enabled: !!userId && !skip && withSports,
  });

  const { createUserSettingAsync } = useUserSetting();

  // Create a user and assign sports and settings if required
  const { mutateAsync: createUserInApiAsync } = useMutation({
    mutationFn: (inputs: {
      firstName: string;
      lastName: string;
      email: string;
      organizationId: string;
      roleId: string;
    }) => {
      return UsersApi.create({
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        email: inputs.email,
        organizationId: inputs.organizationId,
        roleId: inputs.roleId,
      });
    },
    onSuccess: (user: User) => {
      queryClient.invalidateQueries({ queryKey: cacheKeyInvalidator.users() });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationUsers(user.organization.id),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.roleUsers(user.role.id),
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.user-creation-failed"), {
        autoClose: 3000,
      });
    },
  });
  /**
   * create a user and assign sports and settings if required
   * @param inputs
   * @returns {Promise<User>}
   * @async
   */
  async function createUserAsync(inputs: CreateUserAsyncInputs): Promise<User> {
    inputs.lastName = inputs.lastName.toUpperCase(); // Force last name to uppercase

    // Create the user
    const user: User = await createUserInApiAsync(inputs);

    // Assign the sports to the user
    for (const sportId of inputs.sportIds || []) {
      await addSportToUserInApiAsync({ userId: user.id, sportId });
    }

    // Initialize the user settings
    for (const setting of inputs.settings || []) {
      await createUserSettingAsync(user.id, setting);
    }

    return user;
  }

  // Add a sport to the user
  const { mutate: addSportToUserInApi, mutateAsync: addSportToUserInApiAsync } = useMutation({
    mutationFn: (inputs: { userId: string; sportId: string }) => {
      return UsersApi.addSport(inputs.userId, inputs.sportId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.userSports(userId),
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.user.sport-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.user.sport-assignation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function addSportToUser(sportId: string) {
    if (!userId) return;

    addSportToUserInApi({ userId, sportId });
  }

  // Remove a sport from the user
  const { mutate: removeSportFromUserInApi } = useMutation({
    mutationFn: (inputs: { userId: string; sportId: string }) => {
      return UsersApi.removeSport(inputs.userId, inputs.sportId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.userSports(userId),
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.sport-removal-failed"), {
        autoClose: 3000,
      });
    },
  });
  function removeSportFromUser(sportId: string) {
    if (!userId) return;

    removeSportFromUserInApi({ userId, sportId });
  }

  const isLoading = isUserLoading || isSportsLoading;
  const isError = isUserError || isSportsError;

  return {
    user,
    sports,
    isLoading,
    isError,
    createUserAsync,
    addSportToUser,
    removeSportFromUser,
  };
}

export default useUser;
