import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "@/contexts/userContext";
import { SelectOption } from "@ui-lib/select/Select";
import { Sport } from "@user/types/Sport";

function useSportSelect(): {
  sportOptions: SelectOption[]; // The list of sports options to display in the select
  selectedOptionIndex?: number; // The index of the currently selected option
  handleSportSelection: (option: SelectOption) => void; // The function to call when a sport is selected
  isLoading: boolean; // Loading flag
} {
  const navigate = useNavigate();
  const { sports, currentSport, changeSport, isLoading: isUserLoading } = useContext(userContext);

  // Set the sport options
  let sportOptions: SelectOption[] = [];
  if (sports !== undefined && currentSport !== undefined) {
    sportOptions = sports.map((sport: Sport) => ({
      value: sport.id,
      label: sport.name,
    }));
  }

  // Set the selected option index
  let selectedOptionIndex: number | undefined;
  if (currentSport && sportOptions.length > 0)
    selectedOptionIndex = sportOptions.findIndex((option) => option.value === currentSport?.id);

  // Set the loading flag
  const isLoading = isUserLoading;

  /* Handle the sport selection
   * @param option: The selected option
   */
  function handleSportSelection(option: SelectOption) {
    if (!option || !sports) return;

    const newSportPath = sports.find((sport) => sport.id === option.value)?.urlSegment;
    if (!newSportPath) return;
    changeSport(option.value as string);
    navigate(`/admin/pelote/${newSportPath}/competitions`);
  }

  return {
    sportOptions,
    selectedOptionIndex,
    isLoading,
    handleSportSelection,
  };
}

export default useSportSelect;
