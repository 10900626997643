import axios from "axios";

import { apiUrl } from "@/config";
import {
  castApiPaginationInfoToPaginationInfo,
  castApiPermissionToPermission,
  getDefaultHeaders,
} from "./utils";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { Permission } from "@user/types/Permission";
import { buildUrlWithParams } from "@/utils/URL";
import { APIPermission } from "./apiTypes";

export default class PermissionsApi {
  static domain: string = "user";
  static resource: string = "permissions";

  static async index(page: number = 1, pageSize: number = 10): Promise<PaginatedModel<Permission>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${apiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPermissions: PaginatedModel<APIPermission> = response.data;

    const permissions: PaginatedModel<Permission> = {
      items: apiPermissions.items.map((apiPermission) =>
        castApiPermissionToPermission(apiPermission),
      ),
      pagination: castApiPaginationInfoToPaginationInfo(response.data.pagination),
    };

    return permissions;
  }
}
