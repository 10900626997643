import { useState } from "react";

import { CreateUserInputs, CreateUserSettingInputs } from "@user/api/UsersApi";
import usePaginatedOrganizations from "@user/hooks/usePaginatedOrganizations";
import usePaginatedRoles from "@user/hooks/usePaginatedRoles";
import { User } from "@user/types/User";
import useOrganization from "@/domains/user/hooks/useOrganization";
import useUser from "@/domains/user/hooks/useUser";
import { Organization } from "@/domains/user/types/Organization";
import { Role } from "@/domains/user/types/Role";
import useSports from "@/domains/user/hooks/useSports";
import { OrganizationSettingEntityType } from "@/domains/user/types/OrganizationSetting";
import { UserSettingEntityType } from "@/domains/user/types/UserSetting";

function usePageAdminUserCreate(): {
  organizations: Organization[];
  roles: Role[];
  selectedOrganizationId?: string;
  setSelectedOrganizationId: (organizationId: string) => void;
  selectedRoleId?: string;
  setSelectedRoleId: (roleId: string) => void;
  createUser: (createUserInputs: CreateUserInputs) => void;
  createdUser?: User;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  const [createdUser, setCreatedUser] = useState<User | undefined>(undefined);
  const [isCreationPending, setIsCreationPending] = useState(false);
  const [isCreationSuccess, setIsCreationSuccess] = useState(false);

  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>();
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>();

  // Get the organizations
  const { paginatedOrganizations, isLoading: isOrganizationsLoading } = usePaginatedOrganizations({
    pageSize: 1000,
  });
  const organizations = paginatedOrganizations?.items || [];

  // Get the organization roles
  const { paginatedRoles } = usePaginatedRoles({
    pageSize: 1000,
    organizationId: selectedOrganizationId,
    skip: !selectedOrganizationId,
  });
  const roles = paginatedRoles?.items || [];

  // Get the organization details
  const {
    sports,
    organizationSettings,
    isLoading: isOrganizationLoading,
  } = useOrganization({
    organizationId: selectedOrganizationId,
    fetchSports: true,
    fetchSettings: true,
  });

  // Get the user utils functions
  const { createUserAsync } = useUser();

  const { sportRugbyXV, sportRugbyVII } = useSports();

  // Create the user and assign sports and settings
  async function createUser(createUserInputs: CreateUserInputs) {
    setIsCreationPending(true);
    setIsCreationSuccess(false);

    // Get the sport ids to assign to the user
    const sportIds = sports.map((sport) => sport.id);

    // Get the competition settings to assign to the user
    const userSettings: CreateUserSettingInputs[] = [];
    const hasRugbyDomain = sports.some((sport) => sport.domain.name === "Rugby");
    if (hasRugbyDomain) {
      const rugbyCompetitionSettings = organizationSettings.filter(
        (setting) =>
          (setting.sportId === sportRugbyXV.id || setting.sportId === sportRugbyVII.id) &&
          setting.entityType === OrganizationSettingEntityType.FAVORITE_COMPETITION,
      );
      rugbyCompetitionSettings.forEach((setting) => {
        userSettings.push({
          sportId: setting.sportId,
          entityType: UserSettingEntityType.FAVORITE_COMPETITION,
          entityId: setting.entityId,
          settingValue: setting.settingValue,
        });
      });
    }

    const user = await createUserAsync({
      firstName: createUserInputs.firstName,
      lastName: createUserInputs.lastName,
      email: createUserInputs.email,
      organizationId: createUserInputs.organizationId,
      roleId: createUserInputs.roleId,
      sportIds: sportIds,
      settings: userSettings,
    });

    setCreatedUser(user);
    setIsCreationPending(false);
    setIsCreationSuccess(true);
  }

  // Build global isLoading flag
  const isLoading = isOrganizationsLoading || isOrganizationLoading;

  return {
    organizations,
    roles,
    selectedOrganizationId,
    setSelectedOrganizationId,
    selectedRoleId,
    setSelectedRoleId,
    createdUser,
    createUser,
    isLoading,
    isCreationPending,
    isCreationSuccess,
  };
}

export default usePageAdminUserCreate;
