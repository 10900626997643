import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { PaginatedModel } from "@user/types/PaginatedModel";
import useOrganization from "@user/hooks/useOrganization";
import { Permission } from "@user/types/Permission";
import usePaginatedPermissions from "@user/hooks/usePaginatedPermissions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import OrganizationsApi from "@user/api/OrganizationsApi";
import { APIError, APIErrorType } from "@user/types/ApiError";
import { cacheKeyInvalidator } from "@/domains/user/api/apiCache";

/**
 * This interface is used to extend the Permission with the organizationPermissionId if any
 */
export interface ExtendedPermission extends Permission {
  organizationPermissionId?: string;
}

function usePageAdminOrganizationPermissions(): {
  paginatedExtendedPermissions: PaginatedModel<ExtendedPermission> | undefined;
  addPermissionToOrganization: (permissionId: string) => void;
  removePermissionFromOrganization: (permissionId: string) => void;
  permissionsPage: number;
  permissionsPageSize: number;
  setPermissionsPage: (page: number) => void;
  setPermissionsPageSize: (pageSize: number) => void;
  isLoading: boolean;
} {
  const { id: organizationId } = useParams();

  // Handle pagination for organization permissions
  const [permissionsPage, setPermissionsPage] = useState(1);
  const [permissionsPageSize, setPermissionsPageSize] = useState(15);

  // Get the paginated organization permissions
  const { permissions: organizationPermissions, isLoading: isOrganizationPermissionsLoading } =
    useOrganization({
      organizationId,
      fetchPermissions: true,
    });

  // Get the paginated permissions
  const { paginatedPermissions, isLoading: isPermissionsLoading } = usePaginatedPermissions({
    page: permissionsPage,
    pageSize: permissionsPageSize,
  });

  // Build the extended permissions
  const paginatedExtendedPermissionsItems =
    paginatedPermissions?.items.map((permission) => {
      const organizationPermission = organizationPermissions?.find(
        (organizationPermission) => organizationPermission.id === permission.id,
      );

      return {
        ...permission,
        organizationPermissionId: organizationPermission?.id,
      };
    }) ?? [];

  const paginatedExtendedPermissions: PaginatedModel<ExtendedPermission> = {
    items: paginatedExtendedPermissionsItems,
    pagination: {
      page: permissionsPage,
      pageSize: permissionsPageSize,
      totalItems: paginatedPermissions?.pagination.totalItems ?? 0,
    },
  };

  // Add a permission to the organization
  const queryClientAdd = useQueryClient();
  const { mutate: addPermissionToOrganizationInApi } = useMutation({
    mutationFn: (permissionId: string) => {
      if (!organizationId) return new Promise(() => {});
      return OrganizationsApi.addPermission(organizationId, permissionId);
    },
    onSuccess: () => {
      queryClientAdd.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationPermissions(organizationId),
      });

      toast.success(t("admin.organization.permissions-assignation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.organization.permissions-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.organization.permissions-assignation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function addPermissionToOrganization(permissionId: string) {
    addPermissionToOrganizationInApi(permissionId);
  }

  // Remove a permission from the organization
  const queryClientRemove = useQueryClient();
  const { mutate: removePermissionFromOrganizationInApi } = useMutation({
    mutationFn: (permissionId: string) => {
      if (!organizationId) return new Promise(() => {});
      return OrganizationsApi.removePermission(organizationId, permissionId);
    },
    onSuccess: () => {
      queryClientRemove.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationPermissions(organizationId),
      });
      queryClientRemove.invalidateQueries({
        predicate: (query) => cacheKeyInvalidator.rolePermissionsAll(query),
      });

      toast.success(t("admin.organization.permissions-removal-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      console.error(error);
      toast.error(t("admin.organization.permissions-removal-failed"), {
        autoClose: 3000,
      });
    },
  });
  function removePermissionFromOrganization(permissionId: string) {
    removePermissionFromOrganizationInApi(permissionId);
  }

  // Build the global isLoading flag
  const isLoading = isOrganizationPermissionsLoading || isPermissionsLoading;

  return {
    paginatedExtendedPermissions,
    addPermissionToOrganization,
    removePermissionFromOrganization,
    permissionsPage,
    permissionsPageSize,
    setPermissionsPage,
    setPermissionsPageSize,
    isLoading,
  };
}

export default usePageAdminOrganizationPermissions;
