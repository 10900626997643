import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import RolesApi, { CreateRoleInputs } from "@user/api/RolesApi";
import { APIError } from "@pelote/types/ApiError";
import { Organization } from "@user/types/Organization";
import usePaginatedOrganizations from "@user/hooks/usePaginatedOrganizations";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import { Role } from "@user/types/Role";
import { cacheKeyInvalidator } from "@/domains/user/api/apiCache";

function usePageAdminRoleCreate(): {
  isOrganizationSetInSearchParams: boolean;
  organizations: Organization[];
  isLoading: boolean;
  createRole: (createRoleInputs: CreateRoleInputs) => void;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  createdRole: Role | undefined;
} {
  const { t } = useTranslation();
  const { getSearchParam } = useNavigateInApp();

  // Get the organizationId from the search params
  // (filled when creating a role from an organization page)
  const organizationIdFromSearchParams = getSearchParam("organizationId");
  const isOrganizationSetInSearchParams = !!organizationIdFromSearchParams;

  // Fetch the organizations if the organization is not in the search params
  const { paginatedOrganizations, isLoading: isOrganizationsLoading } = usePaginatedOrganizations({
    pageSize: 1000,
    skip: !!organizationIdFromSearchParams,
  });
  const organizations = paginatedOrganizations?.items || [];

  // Handle the creation of a role
  const queryClient = useQueryClient();
  const {
    mutate: createRoleInApi,
    data: createdRole,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newRole: CreateRoleInputs) => {
      return RolesApi.create(newRole);
    },
    onSuccess: (_, inputs) => {
      queryClient.invalidateQueries({ queryKey: cacheKeyInvalidator.roles() });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationRoles(inputs.organizationId),
      });
      toast.success(t("admin.role.role-creation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.role.role-creation-failed"), {
        autoClose: 3000,
      });
    },
  });
  async function createRole(createRoleInputs: CreateRoleInputs) {
    if (organizationIdFromSearchParams) {
      createRoleInputs.organizationId = organizationIdFromSearchParams;
    }
    createRoleInApi(createRoleInputs);
  }

  // Build global isLoading flag
  const isLoading = isOrganizationsLoading;

  return {
    isOrganizationSetInSearchParams,
    organizations,
    isLoading,
    createRole,
    isCreationPending,
    isCreationSuccess,
    createdRole,
  };
}

export default usePageAdminRoleCreate;
