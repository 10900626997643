import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CreateUserInputs } from "@user/api/UsersApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import Loader from "@/components/loader/Loader";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import usePageAdminUserCreate from "./usePageAdminUserCreate";

import "./pageAdminUserCreate.scss";

const PageAdminUserCreate = () => {
  const { navigateTo, navigateBack, getSearchParam } = useNavigateInApp();
  const { t } = useTranslation();

  // Get the organizationId from the search params (filled when the user is created from the organization page)
  const organizationIdFromSearchParams = getSearchParam("organizationId");
  const roleIdFromSearchParams = getSearchParam("roleId");

  const {
    organizations,
    roles,
    selectedOrganizationId,
    setSelectedOrganizationId,
    selectedRoleId,
    setSelectedRoleId,
    createdUser,
    createUser,
    isCreationPending,
    isCreationSuccess,
    isLoading,
  } = usePageAdminUserCreate();

  // Navigate to the user sports page when the creation is successful
  useEffect(
    function navigateIfCreationSuccess() {
      if (!isLoading && isCreationSuccess)
        navigateTo(`/admin/user/users/${createdUser?.id}/sports?creation=true`);
    },
    [isCreationSuccess, isLoading],
  );

  // Initialize the organizationId from the search params if any
  useEffect(
    function initSelectedOrganizationId() {
      if (!organizationIdFromSearchParams) return;

      setValue("organizationId", organizationIdFromSearchParams);
      setSelectedOrganizationId(organizationIdFromSearchParams);
    },
    [organizationIdFromSearchParams],
  );

  // Initialize the roleId from the search params if any
  useEffect(
    function initSelectedRoleId() {
      if (!roleIdFromSearchParams) return;

      setValue("roleId", roleIdFromSearchParams);
      setSelectedRoleId(roleIdFromSearchParams);
    },
    [roleIdFromSearchParams],
  );

  // Build the organizations options
  const organizationsOptions = convertItemsToSelectOptions(organizations, "id", "name");
  organizationsOptions.unshift({ label: "", value: "" });

  // Build the selected organization option
  const selectedOrganizationOption = organizationsOptions.find(
    (organizationOption) => organizationOption.value === selectedOrganizationId,
  );

  // Build the roles options
  const rolesOptions = convertItemsToSelectOptions(roles, "id", "name");
  rolesOptions.unshift({ label: "", value: "" });

  // Build the selected role option
  const selectedRoleOption = rolesOptions.find((roleOption) => roleOption.value === selectedRoleId);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateUserInputs>();

  return (
    <div className="page-admin-user-create">
      <AdminHeader text={t("admin.user.create-user")} />
      <form className="form" onSubmit={handleSubmit(createUser)}>
        <div className="form-fields">
          <FormInput
            id="firstName"
            labelName={t("admin.user.first-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.first-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.firstName?.message}
            register={register}
          />
          <FormInput
            id="lastName"
            labelName={t("admin.user.last-name")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.last-name")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.lastName?.message}
            register={register}
          />
          <FormInput
            id="email"
            labelName={t("admin.user.email")}
            type={InputType.text}
            textToDisplayIfError={`${t("admin.user.email")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.email?.message}
            register={register}
          />
          <FormSelect
            id="organizationId"
            labelName={t("admin.organization.organization")}
            options={organizationsOptions}
            textToDisplayIfError={`${t("admin.organization.organization")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.organizationId?.message}
            register={register}
            onChange={(e) => setSelectedOrganizationId(e.target.value)}
            selectedValue={selectedOrganizationOption?.value as string}
          />
          <FormSelect
            id="roleId"
            labelName={t("admin.role.role")}
            options={rolesOptions}
            textToDisplayIfError={`${t("admin.role.role")} ${t("admin.is-mandatory")}`}
            errorMessage={errors.roleId?.message}
            register={register}
            onChange={(e) => setSelectedRoleId(e.target.value)}
            selectedValue={selectedRoleOption?.value as string}
          />
        </div>
        <div className="form-buttons">
          {isCreationPending ? (
            <Loader />
          ) : (
            <>
              <FormGoBackButton
                labelName={t("admin.cancel")}
                onClick={() => navigateBack({ fallbackRoute: "/admin/user/users" })}
                disabled={isCreationPending}
              />
              <FormSubmit value={t("admin.save")} disabled={isCreationPending} />
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default PageAdminUserCreate;
