import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { PaginatedModel } from "@user/types/PaginatedModel";
import useOrganization from "@user/hooks/useOrganization";
import { User } from "@user/types/User";
import { Organization } from "@user/types/Organization";
import UsersApi from "@user/api/UsersApi";
import { APIError } from "@user/types/ApiError";
import { cacheKeyInvalidator } from "@/domains/user/api/apiCache";

function usePageAdminOrganizationUsers(): {
  organization: Organization | undefined;
  paginatedUsers: PaginatedModel<User> | undefined;
  usersPage: number;
  usersPageSize: number;
  setUsersPage: (page: number) => void;
  setUsersPageSize: (pageSize: number) => void;
  deleteUser: (id: string) => void;
  sendWelcomeEmail: (userId: string) => void;
  isLoading: boolean;
} {
  const { id: organizationId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // Handle pagination for organization users
  const [usersPage, setUsersPage] = useState(1);
  const [usersPageSize, setUsersPageSize] = useState(10);

  // Get the paginated organization users
  const {
    organization,
    users: paginatedUsers,
    isLoading: isUsersLoading,
  } = useOrganization({
    organizationId,
    fetchUsers: true,
    usersPage: usersPage,
    usersPageSize: usersPageSize,
  });

  const { mutate: deleteUserInApi } = useMutation({
    mutationFn: (id: string) => UsersApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: cacheKeyInvalidator.user(id) });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.users(),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationUsers(organizationId),
      });

      toast.success(t("admin.user.user-deletion-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.user-deletion-failed"), {
        autoClose: 3000,
      });
    },
  });
  function deleteUser(id: string) {
    if (confirm(t("admin.user.confirm-user-deletion"))) {
      deleteUserInApi(id);
    }
  }

  // Send welcome email to the user
  const { mutate: sendWelcomeEmailInApi } = useMutation({
    mutationFn: (userId: string) => UsersApi.sendWelcomeEmail(userId),
    onSuccess: (user: User) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.user(user.id),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.users(),
      });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationUsers(user.organization.id),
      });
      toast.success(t("admin.user.welcome-email-sent-successfully"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.welcome-email-sent-failed"), {
        autoClose: 3000,
      });
    },
  });
  function sendWelcomeEmail(userId: string) {
    sendWelcomeEmailInApi(userId);
  }

  // Build the global isLoading flag
  const isLoading = isUsersLoading;

  return {
    organization,
    paginatedUsers,
    usersPage,
    usersPageSize,
    setUsersPage,
    setUsersPageSize,
    deleteUser,
    sendWelcomeEmail,
    isLoading,
  };
}

export default usePageAdminOrganizationUsers;
