import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminRoleUsers from "./usePageAdminRoleUsers";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import { User } from "@user/types/User";
import Button from "@/ui-lib/button/Button";
import Checkbox from "@/ui-lib/checkbox/Checkbox";

import "./pageAdminRoleUsers.scss";

const PageAdminRoleUsers = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigateInApp();

  const { role, paginatedUsers, usersPage, usersPageSize, setUsersPage, isLoading } =
    usePageAdminRoleUsers();

  // Role users table initialization
  const columlHelper = createColumnHelper<User>();
  const columns = [
    columlHelper.accessor("firstName", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.first-name"),
    }),
    columlHelper.accessor("lastName", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.last-name"),
    }),
    columlHelper.accessor("email", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user.email"),
    }),
    columlHelper.accessor("isActive", {
      cell: (info) => <Checkbox defaultChecked={info.getValue()} disabled />,
      header: () => t("admin.organization.enabled"),
    }),
    columlHelper.accessor("role.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.role"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => navigateTo(`/admin/user/users/${info.row.original.id}`)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-role-users">
      <div className="title-bar">
        <div className="title">{t("admin.role.role-users")}</div>
        <Button
          text={t("admin.user.create-user")}
          onClick={() =>
            navigateTo(
              `/admin/user/users/create?organizationId=${role?.organization.id}&roleId=${role?.id}`,
            )
          }
        />
      </div>
      <div className="table-role-users">
        <Table columns={columns} data={paginatedUsers?.items ?? []} hasGlobalFilter={true} />
        <div className="pagination">
          <Pagination
            totalItems={paginatedUsers?.pagination.totalItems ?? 0}
            itemsPerPage={usersPageSize}
            currentPage={usersPage}
            onClick={setUsersPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminRoleUsers;
